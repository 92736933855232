@import './../../colors';

.footer {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-around;
  text-align: start;
  background-color: #000000;
  line-height: 1.2rem;
  padding: 3rem 0 0 0;
  color: #cccccc;

  //border-top: 1px solid var(--SurveyTesterLightBorder);

  & .footer-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 60rem;
    & .column-header {
      color: white;
    }
    & ul {
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
      & li {
        & a {
          text-decoration: none;
          color: #cccccc;
          &:visited {
            color: #cccccc;
          }
          &:hover {
            text-decoration: underline;
            color: #ffffff;
            margin-left: 0;
          }
        }
      }
    }
    & .address {
      width: 33%;
      & a {
        color: #cccccc;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: #ffffff;
        }
      }
    }
    & .legal-links,
    & .segments-links {
      width: 33%;
      //margin-left: 5rem;
    }
    //& .other-links {
    //margin-left: 5rem;
    //}
  }
}
.copyright-line {
  font-size: 0.8rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #000000;
  color: #cccccc;
  & a {
    color: #cccccc;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    padding-left: 1rem;
    padding-right: 1rem;

    & .footer-details {
      & .legal-links,
      & .segments-links,
      & .other-links {
        margin-left: 1rem;
        white-space: nowrap;
      }
    }
  }
}
