@import './../colors';

.contact {
  & .page-content {
    & .free-trial {
      text-align: center;
      & .free-trial-button {
        background-color: var(--SurveyTesterFreeTrialButtonBackground);
        color: var(--SurveyTesterFreeTrialButtonColor) !important;
        padding: 1rem 2rem;
        font-size: 1.25rem;
        border-radius: 0.5rem;
        text-decoration: none !important;
      }
    }
  }
}
