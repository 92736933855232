@import './colors';

.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: var(--SurveyTesterBackgroundColor);
  color: var(--SurveyTesterRegularText);
  overflow: auto;

  & h3 {
    margin-block-start: 1.5rem;
    margin-block-end: 0.5rem;
  }
  & .page {
    & .page-content {
      text-align: start;
      line-height: 1.5;
      & p {
        margin-block-start: 0.5rem;
        margin-block-end: 0.75rem;
      }
      &.bg-top {
        background-color: var(--SurveyTesterBlue);
        //background-image: linear-gradient(to top, #03598a 0%, var(--SurveyTesterBlue) 50%);
        //background-image: linear-gradient(to right, #03598a 0%, var(--SurveyTesterBlue) 50%);
        //background-image: linear-gradient(45deg, #03598a 0%, var(--SurveyTesterBlue) 50%);
        background-image: radial-gradient(
          circle farthest-side at 30% 100%,
          var(--SurveyTesterBlue),
          #003553 65%
        );
        color: var(--SurveyTesterBlueForegroundText);
        padding: 2rem 0;
        & h1 {
          font-weight: normal;
          margin-block-end: 0;
          margin-block-start: 0;
        }

        & h2 {
          font-weight: normal;
          margin-block-start: 0;
        }

        & p {
          font-size: 1.1rem;
        }
        line-height: 1.6;
      }
      &.bg-1 {
        padding: 2rem 0;
        background-color: var(--SurveyTesterBackgroundColor);
        & a {
          text-decoration: none;
          color: var(--SurveyTesterBlue);
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.bg-2 {
        background-color: var(--SurveyTesterBlue);
        //background-color: #333333;
        //background-image: linear-gradient(to top, #333333 0%, #666666 50%);
        //background-image: linear-gradient(to right, #333333 0%, #666666 50%);
        //background-image: linear-gradient(45deg, #333333 0%, #666666 50%);
        //background-image: radial-gradient(circle farthest-side at 30% 100%, #555555, #000000 65%);
        background-image: radial-gradient(
          circle farthest-side at 30% 100%,
          var(--SurveyTesterBlue),
          #003553 65%
        );
        color: var(--bg2-text);
        padding: 2rem 0;
        & a {
          text-decoration: none;
          color: var(--bg2-text);
          &:hover {
            text-decoration: underline;
          }
        }
      }

      & .inner-content {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}
