.register-newsletter-line {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
  & label {
    margin-right: 0.5rem;
  }
  & input[type='email'] {
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: 15rem;
    border: none;
    font-size: 1rem;
    color: black;
    height: unset !important;
  }

  & input[type='button'],
  button {
    border: 1px solid var(--SurveyTesterFreeTrialButtonColor) !important;
    font-size: 1rem;
    background-color: var(--SurveyTesterFreeTrialButtonBackground) !important;
    color: var(--SurveyTesterFreeTrialButtonColor);
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;

    &:hover {
      border-color: red !important;
      background-color: var(--SurveyTesterFreeTrialButtonColor) !important;
      color: red !important;
    }
  }
}
