@import './../colors';

.about {
  & .page-content {
    & .free-trial {
      margin-top: 3rem;
      & .free-trial-button a {
        background-color: var(--SurveyTesterBackgroundColor);
        border: 2px solid var(--SurveyTesterBlue);
        color: var(--SurveyTesterBlue) !important;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        text-decoration: none !important;

        &:hover {
          background-color: var(--SurveyTesterBlue);
          color: var(--SurveyTesterBackgroundColor) !important;
        }
      }
    }
    & .team-mates {
      display: flex;
      flex-wrap: wrap;

      & .team-mate {
        margin: 1rem 2rem;
        width: 9rem;
        & .name {
          font-weight: bold;
        }
        & .title {
          font-size: 0.9rem;
          line-height: normal;
        }
        & img {
          border: 1px solid silver;
          height: 12rem;
          width: 10rem;
          border-radius: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .about {
    & .page-content {
      & .team-mates {
        justify-content: center;
      }
    }
  }
}
