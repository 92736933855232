@import './../colors';

.page.help {
  & .page-content {
    & .help-areas {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      & .help-area {
        text-align: center;
        cursor: pointer;
        & img {
          width: 17rem;

          border: 8px solid transparent;
          border-radius: 1rem;
        }
        & h2 {
          margin-block-start: 0;
          margin-block-end: 3rem;
        }

        &:hover {
          & img {
            border-color: var(--SurveyTesterBlue);
          }
          & h2 {
            color: var(--SurveyTesterBlue);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .pricing {
    & .price-boxes {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 600px) {
}
