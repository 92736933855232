@import './../../colors';

.free-trial-confirmation {
  display: flex;
  border-radius: 1rem;
  padding: 2rem 1rem;
  background-color: var(--SurveyTesterBlue);
  background-image: radial-gradient(
    circle farthest-side at 30% 100%,
    var(--SurveyTesterBlue),
    #003553 65%
  );
  color: var(--SurveyTesterBlueForegroundText);
  & .confirmation-text {
    font-size: 1.5rem;
    margin-left: 2rem;
    margin-right: 3rem;
  }
}

.form {
  & .form-line {
    display: flex;
    margin-bottom: 0.5rem;

    & .form-label {
      width: 10rem;
      white-space: nobreak;
      font-weight: bold;
      flex-shrink: 0;
    }
    & .form-input {
      & input[type='text'] {
        padding: 0.5rem 0.75rem;
        border: 1px solid var(--SurveyTesterLightBorder);
        border-radius: 0.25rem;
        font-size: 1rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;

        &.full-width {
          width: 30rem;
        }
        &.half-width {
          width: calc(14rem - 1px);
        }
      }
      & textarea {
        &.full-width {
          width: 30rem;
          min-height: 5rem;
          padding: 0.5rem 0.75rem;
          border: 1px solid var(--SurveyTesterLightBorder);
          border-radius: 0.25rem;
          font-size: 1rem;
        }
      }
    }
    & .form-privacy {
      font-size: 0.8rem;
      display: flex;
      width: 30rem;
      align-items: flex-start;
      & input[type='checkbox'] {
        margin-right: 0.5rem;
      }
      & a {
        text-decoration: none;
        color: var(--SurveyTesterRegularText);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    & .form-send {
      & input[type='button'] {
        background-color: var(--SurveyTesterFreeTrialButtonBackground);
        color: var(--SurveyTesterFreeTrialButtonColor);
        border: 1px solid var(--SurveyTesterFreeTrialButtonBackground);
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        cursor: pointer;
        margin-top: 1rem;
        &:disabled {
          cursor: auto;
          opacity: 0.5;
          background-color: silver;
          border-color: silver;
        }
      }
    }
  }
}

.other-contact {
  & a {
    color: var(--bg2-text);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  & .register-newsletter-line {
    display: flex;
    align-items: center;
    & label {
      margin-right: 0.5rem;
    }
    & input[type='email'] {
      margin-right: 0.5rem;
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
    }
    & input[type='button'] {
      border: none;
      background-color: var(--SurveyTesterFreeTrialButtonBackground);
      color: var(--SurveyTesterFreeTrialButtonColor);
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    & .page-content {
      & .form {
        & .form-line {
          flex-direction: column;

          & .form-input {
            & input[type='text'] {
              &.full-width {
                width: calc(100% - 3rem) !important;
              }
              &.half-width {
                width: calc(50% - 2.5rem) !important;
              }
            }

            & textarea {
              &.full-width {
                width: calc(100% - 3rem) !important;
              }
            }

            &.form-privacy {
              width: calc(100% - 1rem) !important;
            }
          }
        }
      }
    }
  }
}
