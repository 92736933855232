:root {
  .light {
    --SurveyTesterBackgroundColor: #ffffff;
    --SurveyTesterRegularText: #444444;
    --SurveyTesterLightBorder: #888888;
    --SurveyTesterBlue: #0a97d3;
    --SurveyTesterBlueForegroundText: #ffffff;
    --SurveyTesterFreeTrialButtonBackground: #7bbd00;
    --SurveyTesterFreeTrialButtonColor: #ffffff;
    --SurveyTesterLightText: #888888;
    --bg2-text: white;
  }

  .dark {
    --SurveyTesterBackgroundColor: #000000;
    --SurveyTesterRegularText: #dddddd;
    --SurveyTesterLightBorder: #888888;
    --SurveyTesterBlue: #0a97d3;
    --SurveyTesterBlueForegroundText: #ffffff;
    --SurveyTesterFreeTrialButtonBackground: #7bbd00;
    --SurveyTesterFreeTrialButtonColor: #ffffff;
    --SurveyTesterLightText: #bbbbbb;
    --bg2-text: white;
  }
}
