@import './../../colors';

.calculator {
  & td {
    vertical-align: baseline;
    padding-top: 0.25rem;

    & input[type='number'] {
      width: 3rem;
      margin: 0.25rem 0 0.25rem 1rem;
      padding: 0.25rem 0 0.25rem 0.75rem;
      font-size: 1rem;
      text-align: center;
    }

    &.right-text {
      font-size: 0.9rem;
    }

    & h4 {
      margin-block-start: 0.25rem;
      margin-block-end: 0;
    }

    & .explain {
      font-size: 0.8rem;
      font-style: italic;
    }
    &.td-center {
      text-align: center;
    }
  }
}
