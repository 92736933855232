@import './../colors';

.pricing {
  & .page-content {
    & .free-trial {
      margin-top: 3rem;
      & .free-trial-button a {
        background-color: transparent;
        border: 2px solid var(--SurveyTesterBackgroundColor);
        color: var(--SurveyTesterBackgroundColor) !important;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        text-decoration: none !important;

        &:hover {
          background-color: var(--SurveyTesterBackgroundColor);
          color: var(--SurveyTesterBlue) !important;
        }
      }
    }
    &.bg-1 {
      & .package-currency {
        background-color: transparent;
        color: var(--SurveyTesterRegularText);
        border: 1px solid var(--SurveyTesterLightBorder);
        margin-left: 0.25rem;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        font-weight: bold;
        cursor: pointer;
        &.selected {
          background-color: var(--SurveyTesterBlue);
          color: var(--SurveyTesterBlueForegroundText);
          border-color: var(--SurveyTesterBlue);
        }
      }
      & .price-boxes {
        display: flex;
        //flex-wrap: wrap;
        & .price-box {
          margin: 1rem;
          max-width: 15rem;
          width: 15rem;
          & .package-name {
            padding: 0.25rem 1rem;
            background-color: var(--SurveyTesterBlue);
            color: var(--SurveyTesterBlueForegroundText);
            text-align: center;
            font-weight: bold;
            border: 1px solid var(--SurveyTesterBlue);
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            white-space: nowrap;
          }
          & .package-description {
            border-left: 1px solid var(--SurveyTesterLightBorder);
            border-right: 1px solid var(--SurveyTesterLightBorder);
            padding: 0.25rem 0.5rem;
            font-size: 0.9rem;
            font-style: italic;
            height: calc(100% - 6rem);
          }
          & .package-price {
            padding: 0.25rem 0.5rem;
            border-left: 1px solid var(--SurveyTesterLightBorder);
            border-right: 1px solid var(--SurveyTesterLightBorder);
            border-bottom: 1px solid var(--SurveyTesterLightBorder);
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            white-space: nowrap;
            & .currency {
              font-size: 0.9rem;
              margin-right: 0.25rem;
            }
            & .price {
              font-size: 1.1rem;
              font-weight: bold;
            }
            & .price-per-project {
              font-size: 0.7rem;
            }
          }
          & a {
            color: var(--SurveyTesterRegularText);
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      & .vat-details {
        font-size: 0.8rem;
        margin-bottom: 2rem;
      }
      & .button-container {
        display: flex;
        justify-content: center;

        & .free-trial-button {
          display: flex;
          text-align: center;
          background-color: var(--SurveyTesterFreeTrialButtonBackground);
          color: var(--SurveyTesterFreeTrialButtonColor);
          padding: 0.5rem 1rem;
          border: 1px solid var(--SurveyTesterFreeTrialButtonBackground);
          font-size: 1.1rem;
          margin: 0.5rem auto;
          font-weight: bold;
          border-radius: 0.25rem;
          text-decoration: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .pricing {
    & .price-boxes {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 600px) {
  .pricing {
    & h1,
    & h2,
    & p,
    & .vat-details {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    & .price-boxes {
      flex-direction: column;
      & .price-box {
        width: calc(100% - 2rem) !important;
        max-width: calc(100% - 2rem) !important;
        & .package-price {
          text-align: center;
        }
      }
    }
  }
}
