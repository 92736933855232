@import './../colors';

.home {
  & .page-content {
    & .inner-content {
      & .headline-rocket {
        display: flex;
      }
    }
    & .logo-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      & img {
        height: 4rem;
        margin-right: 3rem;
        margin-top: 2rem;

        &.smaller {
          height: 2.5rem;
        }
      }
    }

    & .pricing {
      margin-top: 2rem;
      margin-bottom: 2rem;
      & .pricing-button a {
        background-color: var(--SurveyTesterBackgroundColor);
        border: 2px solid var(--SurveyTesterBlue);
        color: var(--SurveyTesterBlue) !important;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        text-decoration: none !important;

        &:hover {
          background-color: var(--SurveyTesterBlue);
          color: var(--SurveyTesterBackgroundColor) !important;
        }
      }
    }

    & .free-trial {
      margin-top: 3rem;
      & .free-trial-button a {
        background-color: var(--SurveyTesterFreeTrialButtonBackground);
        border: 2px solid var(--SurveyTesterFreeTrialButtonBackground);
        color: var(--SurveyTesterFreeTrialButtonColor) !important;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        text-decoration: none !important;

        &:hover {
          background-color: var(--SurveyTesterFreeTrialButtonColor);
          color: var(--SurveyTesterFreeTrialButtonBackground) !important;
          border-color: var(--SurveyTesterFreeTrialButtonBackground) !important;
        }
      }
    }
    & .testimonial {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .home {
    & .page-content {
      & .inner-content {
        & .headline-rocket {
          flex-direction: column;
          & #rocket {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2rem;
          }
        }

        & .logo-list {
          flex-direction: column;
          & img {
            margin-right: 0;
          }
        }
      }
    }
  }
}
