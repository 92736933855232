@import './../colors';

.page.help-faq {
  & .page-content {
    & .faq-posts {
      margin-top: 5rem;
      & .faq-post {
        display: flex;
        align-items: flex-start;
        margin-bottom: 5rem;
        & img {
          width: 20rem;
          border-radius: 0.5rem;
          margin-right: 2rem;
        }
        & h3 {
          margin-block-start: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .pricing {
    & .price-boxes {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 600px) {
  .page.help-faq {
    & .page-content {
      & .faq-posts {
        margin-top: 5rem;
        & .faq-post {
          flex-direction: column;
        }
      }
    }
  }
}
