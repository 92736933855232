@import './../../colors';

.top-menu-wrapper {
  background-color: var(--SurveyTesterBackgroundColor);
  position: sticky;
  top: 0;

  & .top-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 60rem;
    img {
      max-height: 3rem;
      padding: 1rem 1rem 1rem 0;
    }
    ul {
      margin: 0 0.25rem;
      padding-inline-start: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      li {
        & a {
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          text-decoration: none;
          color: var(--SurveyTesterRegularText);
          & span {
            border-bottom: 2px solid transparent;
            padding-bottom: 0.25rem;
          }

          &:hover {
            & span {
              border-color: var(--SurveyTesterLightBorder);
              border-radius: 0;
            }
          }

          &:visited {
            color: var(--SurveyTesterRegularText);
          }

          cursor: pointer;
        }

        &.selected {
          & a {
            background-color: var(--SurveyTesterBlue);
            color: var(--SurveyTesterBlueForegroundText);
            & span {
              border-color: transparent;
            }
          }
        }

        &.free-trial-button {
          margin-left: 0.5rem;
          & a {
            padding: 0.25rem 0.5rem;
            &:hover {
              background-color: var(--SurveyTesterFreeTrialButtonBackground);
              color: var(--SurveyTesterFreeTrialButtonColor);
              & span {
                border-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .top-menu-wrapper {
    & .top-menu {
      justify-content: center;

      & ul {
        margin-block-start: 0;
        padding-inline-start: 0;
        margin-block-end: 0;
        //justify-content: space-between;
        & li {
          & a {
            padding: 0.25rem 0.25rem;
            font-size: 0.9rem;
          }
          margin-bottom: 0.75rem;
          //&.free-trial-button {
          //  margin-left: 0;
          //}
        }
      }
    }
  }
}
