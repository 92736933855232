.page-not-found {
  & .page-content {
    & .inner-content {
      & img {
        margin-top: 2rem;
        max-width: 30rem;
        width: 100%;
        border-radius: 1rem;
      }
    }
  }
}
